<template>
  <div ref="rootEl">
    <div class="flex flex-col">
      <div class="flex flex-col">
        <HoppSmartItem
          :label="t('workspace.personal')"
          :icon="IconUser"
          :info-icon="workspace.type === 'personal' ? IconDone : undefined"
          :active-info-icon="workspace.type === 'personal'"
          @click="switchToPersonalWorkspace"
        />
        <hr />
      </div>
      <div v-if="loading" class="flex flex-col items-center justify-center p-4">
        <HoppSmartSpinner class="mb-4" />
        <span class="text-secondaryLight">{{ t("state.loading") }}</span>
      </div>
      <HoppSmartPlaceholder
        v-if="!loading && myTeams.length === 0"
        :src="`/images/states/${colorMode.value}/add_group.svg`"
        :alt="`${t('empty.teams')}`"
        :text="`${t('empty.teams')}`"
      >
        <template #body>
          <HoppButtonSecondary
            :label="t('team.create_new')"
            filled
            outline
            :icon="IconPlus"
            @click="displayModalAdd(true)"
          />
        </template>
      </HoppSmartPlaceholder>
      <div v-else-if="!loading" class="flex flex-col">
        <div
          class="sticky top-0 z-10 mb-2 flex items-center justify-between bg-popover py-2 pl-2"
        >
          <div class="flex items-center px-2 font-semibold text-secondaryLight">
            {{ t("workspace.other_workspaces") }}
          </div>
          <HoppButtonSecondary
            v-tippy="{ theme: 'tooltip' }"
            :icon="IconPlus"
            :title="`${t('team.create_new')}`"
            outline
            filled
            class="ml-8 rounded !p-0.75"
            @click="displayModalAdd(true)"
          />
        </div>
        <HoppSmartItem
          v-for="(team, index) in myTeams"
          :key="`team-${String(index)}`"
          :icon="IconUsers"
          :label="team.name"
          :info-icon="isActiveWorkspace(team.id) ? IconDone : undefined"
          :active-info-icon="isActiveWorkspace(team.id)"
          @click="switchToTeamWorkspace(team)"
        />
      </div>
      <div
        v-else-if="teamListAdapterError"
        class="flex flex-col items-center py-4"
      >
        <icon-lucide-help-circle class="svg-icons mb-4" />
        {{ t("error.something_went_wrong") }}
      </div>
    </div>
    <TeamsAdd
      :show="showModalAdd"
      :switch-workspace-after-creation="true"
      @hide-modal="displayModalAdd(false)"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { useReadonlyStream } from "~/composables/stream"
import { platform } from "~/platform"
import { useI18n } from "@composables/i18n"
import IconUser from "~icons/lucide/user"
import IconUsers from "~icons/lucide/users"
import IconPlus from "~icons/lucide/plus"
import { useColorMode } from "@composables/theming"
import { GetMyTeamsQuery } from "~/helpers/backend/graphql"
import IconDone from "~icons/lucide/check"
import { useLocalState } from "~/newstore/localstate"
import { defineActionHandler, invokeAction } from "~/helpers/actions"
import { WorkspaceService } from "~/services/workspace.service"
import { useService } from "dioc/vue"
import { useElementVisibility, useIntervalFn } from "@vueuse/core"
import { debounce } from "lodash-es"

const t = useI18n()
const colorMode = useColorMode()

const showModalAdd = ref(false)

const currentUser = useReadonlyStream(
  platform.auth.getProbableUserStream(), //probableUser$,
  platform.auth.getProbableUser() //probableUser$.value,
)

const workspaceService = useService(WorkspaceService) // 使用工作空间的服务
const teamListadapter = workspaceService.acquireTeamListAdapter(null) // 使用team 的适配器
const myTeams = useReadonlyStream(teamListadapter.teamList$, []) // 读取team 信息
const isTeamListLoading = useReadonlyStream(teamListadapter.loading$, false) // loading
const teamListAdapterError = useReadonlyStream(teamListadapter.error$, null) // error
const REMEMBERED_TEAM_ID = useLocalState("REMEMBERED_TEAM_ID") // 本地选中的team id  用来标记选中状态 如果没有就是个人空间
const teamListFetched = ref(false) // flag

const rootEl = ref<HTMLElement>()
const elVisible = useElementVisibility(rootEl)

const { pause: pauseListPoll, resume: resumeListPoll } = useIntervalFn(() => {
  if (teamListadapter.isInitialized) {
    teamListadapter.fetchList()
  }
}, 10000)
const debouncedSearch = debounce(teamListadapter.fetchList, 100)

watch(
  elVisible, // 监控选择器是否可见
  () => {
    console.log("aaaa", elVisible.value)
    if (elVisible.value) {
      debouncedSearch() // 改一下避免连续多次调用

      resumeListPoll()
    } else {
      pauseListPoll()
    }
  },
  { immediate: true }
)

watch(myTeams, (teams) => {
  if (teams && !teamListFetched.value) {
    teamListFetched.value = true
    if (REMEMBERED_TEAM_ID.value && currentUser.value) {
      const team = teams.find((t) => t.id === REMEMBERED_TEAM_ID.value)
      if (team) switchToTeamWorkspace(team)
    }
  }
})

const loading = computed(
  () => isTeamListLoading.value && myTeams.value.length === 0
)

const workspace = workspaceService.currentWorkspace

const isActiveWorkspace = computed(() => (id: string) => {
  if (workspace.value.type === "personal") return false
  return workspace.value.teamID === id
})

const switchToTeamWorkspace = (team: GetMyTeamsQuery["myTeams"][number]) => {
  REMEMBERED_TEAM_ID.value = team.id // 本地记录选中的workspace
  workspaceService.changeWorkspace({
    // 调用workspace service  to change workspace
    teamID: team.id,
    teamName: team.name,
    type: "team",
    role: team.myRole,
  })
}

const switchToPersonalWorkspace = () => {
  REMEMBERED_TEAM_ID.value = undefined // clean
  workspaceService.changeWorkspace({
    type: "personal",
  })
}

watch(
  () => currentUser.value,
  (user) => {
    if (!user) {
      switchToPersonalWorkspace() // 用户登出， 默认切换到个人空间
      teamListadapter.dispose() // 并销毁适配器
    }
  }
)

const displayModalAdd = (shouldDisplay: boolean) => {
  if (!currentUser.value) return invokeAction("modals.login.toggle") // 提示登录

  showModalAdd.value = shouldDisplay
  teamListadapter.fetchList() // 否则重新获取列表
}

defineActionHandler("modals.team.new", () => {
  // 订阅了创建新team 的事件， 并处理
  displayModalAdd(true)
})

defineActionHandler("workspace.switch.personal", switchToPersonalWorkspace)
defineActionHandler("workspace.switch", ({ teamId }) => {
  // 工作空间被切换之后，这里也自动跟着切换工作空间
  const team = myTeams.value.find((t) => t.id === teamId)
  if (team) switchToTeamWorkspace(team)
})
</script>
