<template>
  <HoppSmartModal
    v-if="show"
    dialog
    :title="t('app.ai_help')"
    @close="hideModal"
  >
    <template #body>
      <div class="flex flex-col space-y-4">
        <div class="flex space-x-2">
          <div class="flex grow">
            <HoppSmartInput
              v-model="msg"
              placeholder=" "
              :label="t('app.ai_help_placehold')"
              input-styles="floating-input"
              @submit="submitAI"
              :class="'w-full'"
              @focus="inputFocus"
            />
          </div>
          <div class="flex">
            <HoppButtonPrimary
              :label="t('action.send')"
              outline
              @click="submitAI"
            />
          </div>
        </div>
        <div v-if="haveLoaded">
          <div v-if="isLoading" class="flex flex-col items-center py-3">
            <HoppSmartSpinner />
          </div>
          <div v-else class="flex flex-col items-center py-3">
            <textarea class="w-full h-20" readonly :value="doc"></textarea>
            <HoppButtonPrimary :label="'Import'" outline @click="submitAI" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <p class="text-secondaryLight">
        {{ t("app.ai_help_description") }}
      </p>
    </template>
  </HoppSmartModal>
</template>

<script setup lang="ts">
import IconCopy from "~icons/lucide/copy"
import IconCheck from "~icons/lucide/check"
import { refAutoReset, useVModel } from "@vueuse/core"
import { useI18n } from "@composables/i18n"
import { useToast } from "@composables/toast"
import { ref, watch, reactive, computed, onMounted } from "vue"
import axios from "axios"
import { WorkspaceService } from "~/services/workspace.service"
import { useService } from "dioc/vue"
import { cloneDeep } from "lodash-es"

const workspaceService = useService(WorkspaceService)

const workspace = workspaceService.currentWorkspace

const currentWorkspace = computed(() => {
  // if (workspace.value.type === "personal") {
  //   return t("workspace.personal")
  // }

  console.log("ssssssssssssssss1", workspace.value)
  return workspace.value
})

onMounted(() => {
  console.log("ssssssssssssssss2", cloneDeep(workspace.value))
})

const t = useI18n()

const toast = useToast()

const props = withDefaults(
  defineProps<{
    show: boolean
  }>(),
  {
    show: false,
  }
)

const emit = defineEmits<{
  (e: "hide-modal"): void
}>()

const copyIcon = refAutoReset<typeof IconCopy | typeof IconCheck>(
  IconCopy,
  1000
)
const msg = ref("")
const isLoading = ref(false)
const res = ref({})
const haveLoaded = ref(false)

watch(
  () => props.show,
  (show) => {
    if (!show) {
      msg.value = ""
    }
  }
)

const hideModal = () => {
  emit("hide-modal")
}

const submitAI = async () => {
  isLoading.value = true
  const response = await axios.post(
    `${import.meta.env.VITE_BACKEND_API_URL}/ai/generate/aaaa`,
    {
      msg,
    },
    {
      withCredentials: true,
    }
  )

  if (response) {
    haveLoaded.value = true
    res.value = response.data
  } else {
    throw new Error("test: does not get device identifier")
  }

  isLoading.value = false
}

const inputFocus = () => {
  res.value = {}
  haveLoaded.value = false
}

const doc = computed(() => {
  console.log(JSON.stringify(res.value, null, "\t"))
  return JSON.stringify(res.value, null, "\t")
})
</script>

<style lang="scss" scoped></style>
