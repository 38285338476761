

import __layout_0 from '/home/ubuntu/repo/testwise.ca/packages/hoppscotch-common/src/layouts/default.vue'
export const layouts = {
'default': __layout_0,
'empty': () => import('/home/ubuntu/repo/testwise.ca/packages/hoppscotch-common/src/layouts/empty.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
